import styled from "@emotion/styled";
import * as GLOBAL_VARS from '@utils/globals';
import RES from '@utils/resources';

const AnimationBackgroundOverlay = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.common.background,
    position: 'fixed',
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999
}));

function Animation({ showAnimation, onAnimationEnd, identifier }) {
    const openingAnimation = () => {
        let src;
        RES.openingAnimations.forEach((animation) => {
            if (animation.template_id === identifier) {
                src = animation.openingAnimation;
            }
        });
        return src;
    };

    return  showAnimation ?
        <AnimationBackgroundOverlay>
            <video autoPlay
                playsInline
                width="100%"
                height="100%"
                onEnded={onAnimationEnd}
            >
                <source
                    src={openingAnimation()}
                    type="video/mp4"
                />
            </video>
        </AnimationBackgroundOverlay> : null;
}

export default Animation;
