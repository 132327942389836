import styled from '@emotion/styled';
import RES from '@utils/resources';
import Container from '@components/Container';

import PoweredByWax from '@images/powered-by-WAX.svg';
import AtomicAssetsLogo from '@images/atomic-assets-logo.png';
import FacingsLogo from '@images/facings-logo-white.svg';
import TelegramLogo from '@images/telegram-icon.svg';
import TwitterLogo from '@images/twitter-icon.svg';
import DiscordLogo from '@images/discord.svg';
import InstagramLogo from "@images/instagram-logo.svg";
import FacebookLogo from "@images/facebook-icon.svg";
import ROUTES from '@utils/routes';
import theme from '../styles/theme';

const StyledFooter = styled.div(({ theme, style }) => ({
    padding: `${theme.spacing.l} ${theme.spacing.xs}`,
    marginTop: 'auto',
    display: 'flex',
    gap: theme.spacing.xs,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.common.background,
    [theme.mediaQuery.tabletUp]: {
        justifyContent: 'space-evenly'
    },
    ...style
}));

const Row = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

const CopyrightColumn = styled.div(({ theme }) => ({
    ...theme.typography.p,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.xs,
    'a': {
        color: theme.colors.common.text,
        margin: `0 ${theme.spacing.xs}`
    },
    'a:hover': {
        color: theme.colors.primary.main
    },
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: 'row'
    }
}));

const SocialMediaLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    margin: `0 ${theme.spacing.xs}`
}));

const CenteredColumn = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FooterLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    userSelect: 'none',
    [theme.mediaQuery.tabletUp]: {
        marginTop: 0
    }
}));

const Text = styled.p(({ theme }) => ({
    ...theme.typography.pBold,
    marginBottom: theme.spacing.xxs,
    color: theme.colors.common.text
}));

const TextTiny = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.text
}));

function Footer({ style }) {
    return (
        <StyledFooter>
            <Row>
                <SocialMediaLink href={RES.externalLinks.facingsDiscord.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '24px' }} src={DiscordLogo}  alt={RES.externalLinks.facingsDiscord.label} />
                </SocialMediaLink>
                <SocialMediaLink href={RES.externalLinks.fanfavzInstagram.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '24px' }} src={InstagramLogo}  alt={RES.externalLinks.fanfavzInstagram.label} />
                </SocialMediaLink>
                <SocialMediaLink href={RES.externalLinks.fanfavzTwitter.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '24px' }} src={TwitterLogo}  alt={RES.externalLinks.fanfavzTwitter.label} />
                </SocialMediaLink>
                <SocialMediaLink href={RES.externalLinks.fanfavzFacebook.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '24px' }} src={FacebookLogo}  alt={RES.externalLinks.fanfavzFacebook.label} />
                </SocialMediaLink>
                <SocialMediaLink href={RES.externalLinks.facingsTelegram.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '24px' }} src={TelegramLogo}  alt={RES.externalLinks.facingsTelegram.label} />
                </SocialMediaLink>
            </Row>
            <Row>
                <Text>
                    FanFavz, LLC
                </Text>
            </Row>
            <Container
                direction="row"
                gap={theme.spacing.s}
            >
                <FooterLink href={RES.externalLinks.wax.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '100px' }} src={PoweredByWax}  alt={RES.externalLinks.wax.label} />
                </FooterLink>
                <CenteredColumn>
                    <TextTiny>In partnership with</TextTiny>
                    <FooterLink  href={RES.externalLinks.facings.link} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '75px', margin: '0 25px' }} src={FacingsLogo} alt={RES.externalLinks.facings.label} />
                    </FooterLink>
                </CenteredColumn>
            </Container>
            <Row>
                <CopyrightColumn>
                    <FooterLink href={`/#${ROUTES.privacypolicy}`} >Privacy Policy</FooterLink>
                    <FooterLink href={`/#${ROUTES.termsandconditions}`} >Terms &amp; Conditions</FooterLink>
                    <FooterLink href={`/#${ROUTES.nftlicense}`} >NFT License</FooterLink>
                </CopyrightColumn>
            </Row>
        </StyledFooter>
    );
}

export default Footer;
