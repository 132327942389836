import React, { useState, useEffect } from 'react';
import { withUAL } from 'ual-reactjs-renderer';
import Button from '@components/Button';
import Grid from '@components/Grid';
import ErrorModal from '@components/ErrorModal';
import ConfirmationModal from '@components/ConfirmationModal';
import Loading from '@components/Loading';
import { getInventory, getAssetId, transferAsset, getOpenedFromAsset, getTemplateIdFromAsset, burnAsset, getSerialFromAsset, getRedeemableList } from '@api/api';
import STRINGS from '@utils/strings';
import { IPFS_ENDPOINT, PACKS_TEMPLATE_LIST, INVENTORY_UNPACK, SHOW_PACK_HISTORY } from '@utils/globals';
import { useHistory } from 'react-router-dom';
import ROUTES from '@utils/routes';
import { Pagination } from 'element-react';
import styled from '@emotion/styled';
import theme from '@styles/theme';
import RES from '@utils/resources';
import { isCPUErrorMessage, isNETErrorMessage } from '@utils/utils';
import Tabs from '@components/Tabs';
import Animation from '@components/Animation';
import Container from '@components/Container';
import Select from 'react-select/creatable';
import { isMobileOnly } from 'react-device-detect';
import RedeemAsset from '@components/RedeemAsset';

import atomicHubLogo from '@images/atom-icon.png';

import '@styles/theme/index.css';

const InventoryNoticeContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.l,
    color: theme.colors.common.text
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    margin: theme.spacing.xxs,
    width: 48,
    objectFit: 'contain'
}));

const ConfirmationModalDisclaimer = styled.p(({ theme }) => ({
    marginBottom: theme.spacing.m
}));

const CPUErrorMessage = styled.p(({ theme }) => ({
    ...theme.typography.p,
    margin: theme.spacing.s,
    textAlign: 'initial',
    a: {
        color: theme.colors.secondary.main,
        '&:hover': {
            color: theme.colors.secondary.dark
        }
    }
}));

const TabsDiv = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing.s
}));

const tabs = [
    STRINGS.allAssets,
    STRINGS.packsOnly,
    STRINGS.cardsOnly
];

const Filter = styled.div(() => ({
    width: '100%',
    maxWidth: '210px'
}));

const NoAssetsFound = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    fontWeight: '600'
}));

function Inventory({ ual }) {
    const history = useHistory();

    const [ assets, setAssets ] = useState([]);
    const [ packs, setPacks ] = useState([]);
    const [ cards, setCards ] = useState([]);
    const [ assetsToDisplay, setAssetsToDisplay ] = useState(tabs[0]);
    const [ loading, setLoading ] = useState(true);
    const [ selectedAsset, setSelectedAsset ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ paginationWindow, setPaginationWindow ] = useState({ start: 0, end: 0 });
    const [ showBurnConfirmation, setShowBurnConfirmation ] = useState(false);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ showAnimation, setShowAnimation ] = useState(false);
    const [ selectedPackId, setSelectedPackId ] = useState(null);
    const [ selectedTemplateId, setSelectedTemplateId ] = useState(null);
    const [ selectedMintNumber, setSelectedMintNumber ] = useState(null);
    const [ selectedName, setSelectedName ] = useState(null);
    const [ showSuccess, setShowSuccess ] = useState(false);

    const [ variants, setVariants ] = useState([]);
    const [ selectedVariant, setSelectedVariant ] = useState([]);
    const [ rarities, setRarities ] = useState([]);
    const [ selectedRarity, setSelectedRarity ] = useState([]);
    const [ cardId, setCardId ] = useState([]);
    const [ selectedCardId, setSelectedCardId ] = useState([]);
    const [ filteredAssets, setFilteredAssets ] = useState([]);
    const [ assetsToFilter, setAssetsToFilter ] = useState([]);

    const [ redeemable, setRedeemable ] = useState([]);
    const [ showRedeem, setShowRedeem ] = useState(false);
    const [ assetToRedeem, setAssetToRedeem ] = useState({});
    const [ redeemableList, setRedeemableList ] = useState([]);
    const [ successfullyRedeemed, setSuccessfullyRedeemed ] = useState(false);

    const paginationSize = 24;

    function loadInventory() {
        if (ual.activeUser) {
            getInventory(ual.activeUser.accountName, (assets) => { setAssets(assets); setLoading(false); }, (errorMessage) => setErrorMessage(errorMessage));
        }
        getRedeemableList(setRedeemableList);
        setSuccessfullyRedeemed(false);
    }

    if (redeemableList.length > 0 && redeemable.length > 0) {
        tabs[3] = STRINGS.redeemable;
    }

    useEffect(() => {
        // scroll to the very top when page is triggered.
        window.scrollTo(0, 0);

        loadInventory();
    }, []);

    useEffect(() => {
        if (successfullyRedeemed) {
            window.scrollTo(0, 0);
            loadInventory();
        }
    }, [successfullyRedeemed]);

    useEffect(() => {
        let filteredPacksArray = assets.filter(asset => {
            let assetTemplateId = getTemplateIdFromAsset(asset);
            return PACKS_TEMPLATE_LIST.includes(assetTemplateId);
        });

        filteredPacksArray.sort((assetA, assetB) => {
            // If both assets can/can't be opened, order is unaltered
            if (canOpenAsset(assetA) === canOpenAsset(assetB)) {
                return 0;
            } else {
                // Unopened assets (not wrapper) are moved up
                if (canOpenAsset(assetB)) {
                    return 1;
                // Assets that cannot be opened (wrapper) are moved down
                } else {
                    return -1;
                }
            }
        });

        let filteredCardsArray = assets.filter(asset => {
            let assetTemplateId = getTemplateIdFromAsset(asset);
            return !PACKS_TEMPLATE_LIST.includes(assetTemplateId);
        });

        let filteredRedeemable = assets.filter(asset => {
            let assetTemplateId = getTemplateIdFromAsset(asset);
            return redeemableList.filter(item => item.template_id === assetTemplateId)[0];
        });

        let filteredVariants = [];
        let filteredRarities = [];
        let filteredCardId = [];

        getFilterOptions('variant', filteredVariants);
        getFilterOptions('rarity', filteredRarities);
        getFilterOptions('cardid', filteredCardId);

        let variantsOptions = [];
        let raritiesOptions = [];
        let cardIdOptions = [];

        createSelectOptions(filteredVariants, variantsOptions);
        createSelectOptions(filteredRarities, raritiesOptions);
        createSelectOptions(filteredCardId, cardIdOptions, true);

        setVariants(variantsOptions);
        setRarities(raritiesOptions);
        setCardId(cardIdOptions);

        setPacks(filteredPacksArray);
        setCards(filteredCardsArray);
        setRedeemable(filteredRedeemable);
    }, [assets]);

    const getFilterOptions = (filterProperty, results) => {
        assets.filter(asset => {
            if (asset.data[filterProperty] && !results.includes(asset.data[filterProperty])) {
                results.push(asset.data[filterProperty]);
            }
        });
    };

    const createSelectOptions = (array, result, sortNumbers) => {
        const optionsArray = !sortNumbers ? array.sort() : array.sort((a, b) => {return b - a;}).reverse();

        optionsArray.map(item => {
            result.push({ value: item, label: item });
        });
    };

    useEffect(() => {
        if (assetsToDisplay === STRINGS.packsOnly) {
            setAssetsToFilter(packs);
        } else if (assetsToDisplay === STRINGS.cardsOnly) {
            setAssetsToFilter(cards);
        } else if (assetsToDisplay === STRINGS.redeemable) {
            setAssetsToFilter(redeemable);
        } else {
            setAssetsToFilter(assets.sort(compareTimestamp));
        }
    }, [ assetsToDisplay, assets, packs, cards, redeemable ]);

    const compareTimestamp = (a, b) => {
        return parseInt(b.updated_at_time) - parseInt(a.updated_at_time);
    };

    useEffect(() => {
        let filter = {
            variant: selectedVariant ? selectedVariant.value : undefined,
            rarity: selectedRarity ? selectedRarity.value : undefined,
            cardid: selectedCardId ? selectedCardId.value: undefined
        };

        const allAssets = assetsToFilter ? assetsToFilter : assets;

        let filteredAssets = allAssets.filter(asset => {
            let result = true;

            for (const key in filter) {
                if (filter[key] !== undefined) {
                    result = result && (asset.data[key] === filter[key]);
                }
            }

            return result;
        });

        setFilteredAssets(filteredAssets);
    }, [ selectedVariant, selectedRarity, selectedCardId, assetsToFilter ]);

    const changePage = (pageNumber) => {
        setPaginationWindow({ start: (pageNumber - 1) * paginationSize, end: Math.min(pageNumber * paginationSize, assets.length) });
    };

    useEffect(() => {
        setPaginationWindow({ start: 0, end: Math.min(paginationSize, assets.length) });
    }, [assets]);

    function openPack(packToOpen) {
        setSelectedPackId(packToOpen.id);
        setSelectedMintNumber(packToOpen.badge);
        setSelectedTemplateId(packToOpen.templateId);
        setSelectedName(packToOpen.caption);
        setShowConfirmation(true);
    }

    function onOpenPackConfirmation() {
        setShowConfirmation(false);
        transferAsset(ual.activeUser, selectedPackId, () => {
            setShowAnimation(true);
        }, () => setErrorMessage(STRINGS.defaultErrorMessage));
    }

    function canOpenAsset(asset) {
        return getOpenedFromAsset(asset) === 0;
    }

    const burnAssetConfirm = () => {
        const onBurnCallback = () => {
            setLoading(true);
            setAssets([]);
            setTimeout(loadInventory, 500);
            setSelectedAsset(null);
            setShowBurnConfirmation(false);
        };
        const onError = (errorMessage) => {
            setErrorMessage(errorMessage);
            setShowBurnConfirmation(false);
        };
        burnAsset(ual.activeUser, getAssetId(selectedAsset), onBurnCallback, onError);
    };

    const onBurnAsset = (asset) => {
        setSelectedAsset(assets.filter(a => getAssetId(a) === asset.id)[0]);
        setShowBurnConfirmation(true);
    };

    const getConfirmationModalTitle = () => {
        let title = '';
        if (selectedAsset != null) {
            if (PACKS_TEMPLATE_LIST.includes(getTemplateIdFromAsset(selectedAsset)) && selectedAsset.data.opened === 0) {
                title = STRINGS.openPackConfirmationTitle.replace('{0}', selectedAsset.data.name).replace('{1}', getSerialFromAsset(selectedAsset));
            } else {
                title = STRINGS.burnConfirmationTitle.replace('{0}', selectedAsset.data.name).replace('{1}', getSerialFromAsset(selectedAsset));
            }
        }
        return title;
    };

    const recentlyOpened = (assetTimestamp) => {
        const currentDate = new Date();
        const latestTimeOut = new Date(currentDate.getTime() - 15 * 60000);

        return latestTimeOut.getTime() < parseInt(assetTimestamp) ? true : false;
    };

    const redeemAsset = (item) => {
        setAssetToRedeem(item);
        setShowRedeem(true);
    };

    function onCloseRedeem() {
        setAssetToRedeem({});
        setShowRedeem(false);
    }

    useEffect(() => {
        document.body.style.overflow = showRedeem ? 'hidden' : 'auto';
    }, [showRedeem]);

    const redemptionInfo = (template_id) => {
        return redeemableList.filter(item => item.template_id === template_id)[0];
    };

    const itemsToShow = (list) => list ? list.slice(paginationWindow.start, paginationWindow.end).map(asset => {
        const isImage = asset.data.img != null;
        const redemption = redemptionInfo(getTemplateIdFromAsset(asset));
        asset.redemption_type = redemption ? redemption.type : null;

        return {
            id: getAssetId(asset),
            srcImg: `${IPFS_ENDPOINT}${isImage ? asset.data.img : asset.data.video}`,
            badge: `#${getSerialFromAsset(asset) === undefined ? asset.template_mint : getSerialFromAsset(asset)}`,
            caption: asset.data.name,
            templateId: getTemplateIdFromAsset(asset),
            type: isImage ? 'image' : 'video',
            srcBackImg: asset.data.backimg ? `${IPFS_ENDPOINT}${asset.data.backimg}` : null,
            recentlyOpened: recentlyOpened(asset.updated_at_time),
            buttons: INVENTORY_UNPACK && canOpenAsset(asset) ?
                [{
                    label: STRINGS.viewAsset,
                    secondary: true
                },
                {
                    label: STRINGS.openPack,
                    onClick: openPack
                }]
                :
                asset.redemption_type !== null ?
                    [{
                        label: STRINGS.viewAsset
                    },
                    {
                        label: STRINGS.redeemAsset,
                        onClick: () => redeemAsset(asset)
                    }
                    ] : [
                        {
                            label: STRINGS.viewAsset
                        }
                    ]
        };
    }) : [];

    const renderAssetsGrid = (items) => {
        return (
            <>
                <Grid canExpand items={items} />
                <Pagination small={isMobileOnly ? true : false} background layout="prev, pager, next" total={filteredAssets.length} pageSize={paginationSize} onCurrentChange={(currentPage) => changePage(currentPage)} />
            </>
        );
    };

    const renderAssets = () => {
        if (filteredAssets.length > 0) {
            return renderAssetsGrid(itemsToShow(filteredAssets));
        } else {
            return (
                <Container padding={theme.spacing.l}>
                    <NoAssetsFound>{STRINGS.noAssetsFound}</NoAssetsFound>
                </Container>
            );
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {
                loading ? <Loading /> :
                    <>
                        <InventoryNoticeContainer>
                            <h4>{STRINGS.viewCollection}</h4>
                            <a href={RES.secondaryMarketLinks.atomicHubInventory.link.replace("{0}", ual.activeUser.accountName)} target='_blank' rel='noreferrer noopener'>
                                <SecondaryMarketImage src={atomicHubLogo} alt={RES.secondaryMarketLinks.atomicHubInventory.label} />
                            </a>
                        </InventoryNoticeContainer>
                        <TabsDiv>
                            <Tabs tabs={tabs} onChange={setAssetsToDisplay} selectedTab={assetsToDisplay} />
                        </TabsDiv>
                        <Container direction="row" padding={theme.spacing.l} gap={theme.spacing.xs}>
                            <Filter>
                                <Select
                                    options={variants}
                                    placeholder={STRINGS.variantFilter}
                                    onChange={setSelectedVariant}
                                    isClearable
                                    styles={theme.select}
                                />
                            </Filter>
                            <Filter>
                                <Select
                                    options={rarities}
                                    placeholder={STRINGS.rarityFilter}
                                    onChange={setSelectedRarity}
                                    isClearable
                                    styles={theme.select}
                                />
                            </Filter>
                            <Filter>
                                <Select
                                    options={cardId}
                                    placeholder={STRINGS.cardIdFilter}
                                    onChange={setSelectedCardId}
                                    isClearable
                                    styles={theme.select}
                                />
                            </Filter>
                        </Container>
                        { renderAssets() }
                        <div style={{ margin: theme.spacing.s }}>
                            <a href={RES.secondaryMarketLinks.atomicHubEntireCollection.link.replace("{0}", ual.activeUser.accountName)} target='_blank' rel='noreferrer noopener'>
                                <Button secondary>
                                    {STRINGS.viewEntireCollection}
                                </Button>
                            </a>
                        </div>
                        <div style={{ margin: theme.spacing.s }}>
                            <Button onClick={() => history.push(ROUTES.buypacks)}>
                                {STRINGS.buyMorePacks}
                            </Button>
                        </div>
                        <ErrorModal show={errorMessage !== ""} onClose={() => setErrorMessage("")} >
                            {errorMessage}
                            { isCPUErrorMessage(errorMessage) ?
                                <CPUErrorMessage>
                                    {STRINGS.cpuErrorMessage}
                                </CPUErrorMessage> :
                                ""
                            }
                            { isNETErrorMessage(errorMessage) ?
                                <CPUErrorMessage>
                                    {STRINGS.netErrorMessage}
                                </CPUErrorMessage> :
                                ""
                            }
                        </ErrorModal>
                        <ConfirmationModal
                            show={showBurnConfirmation}
                            onConfirm={burnAssetConfirm}
                            onClose={() => setShowBurnConfirmation(false)}
                            confirmationButtonLabel={
                                selectedAsset &&
                                PACKS_TEMPLATE_LIST.includes(getTemplateIdFromAsset(selectedAsset)) &&
                                selectedAsset.data.opened === 0 ?
                                    STRINGS.openAndBurn : STRINGS.burn
                            }
                            title={getConfirmationModalTitle()}
                        >
                            <ConfirmationModalDisclaimer>{STRINGS.burnConfirmationWarning}</ConfirmationModalDisclaimer>
                        </ConfirmationModal>
                        {
                            INVENTORY_UNPACK ?
                                <>
                                    <ConfirmationModal
                                        show={showConfirmation}
                                        onConfirm={onOpenPackConfirmation}
                                        onClose={() => setShowConfirmation(false)}
                                        confirmationButtonLabel={STRINGS.openPack}
                                        title={STRINGS.openPackConfirmation.replace("{0}", selectedName).replace("{1}", selectedMintNumber === '0' ? `? ` : selectedMintNumber)}
                                    />
                                    <Animation
                                        showAnimation={showAnimation}
                                        onAnimationEnd={() => {
                                            setTimeout(() => {
                                                setShowAnimation(false);
                                                SHOW_PACK_HISTORY ?
                                                    history.push(`${ROUTES.packrips}${ROUTES.view}/${selectedPackId}`)
                                                    :
                                                    history.push(ROUTES.inventory);
                                            }, 2000);
                                        }}
                                        identifier={selectedTemplateId}
                                    />
                                    <ConfirmationModal
                                        show={showSuccess}
                                        onConfirm={() => history.push(ROUTES.packrips)}
                                        onClose={() => { setShowSuccess(false); loadInventory(); }}
                                        confirmationButtonLabel={STRINGS.goToPackRips}
                                        cancelButtonLabel={STRINGS.close}
                                        title={STRINGS.openPackSuccess}
                                    />
                                </>
                                :null
                        }
                        {showRedeem ? (
                            <RedeemAsset
                                show={showRedeem}
                                asset={assetToRedeem}
                                onClose={onCloseRedeem}
                                setSuccessfullyRedeemed={setSuccessfullyRedeemed}
                            />
                        ) : null}
                    </>
            }
        </div>
    );
}

export default withUAL(Inventory);
