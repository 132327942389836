import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import theme from '../styles/theme';
import NewsHeader from '@images/tommy-hearns-news.png';
import Container from '@components/Container';
import STRINGS from '@utils/strings';

const HeaderImg = styled.img(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    zIndex: 1,
    marginBottom: theme.spacing.xl,
    [ theme.mediaQuery.largeMobileOnly ]: {
        width: '100%',
        height: 'auto',
        maxHeight: '500px'
    }
}));

const NewsItem = styled.a(() => ({
    lineHeight: '120%',
    height: '300px',
    position: 'relative',
    maxWidth: '300px',
    transition: theme.transition.default,
    '&:hover': {
        transform: 'scale(1.1)'
    }
}));

const NewsSource = styled.span(({ theme }) => ({
    color: theme.colors.primaryLight,
    lineHeight: '160%',
    fontSize: '1.125rem',
    fontWeight: 'bolder'
}));

const NewsTitle = styled.span(({ theme }) => ({
    color: theme.colors.primaryLight,
    lineHeight: '130%',
    fontSize: '16px',
    textAlign: 'left'
}));

const ContentContainer = styled.div(() => ({
    height: '300px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    background: 'linear-gradient( to top, black, rgba(255, 0, 0, 0))'
}));

const Image = styled.img(() => ({
    objectFit: 'cover',
    width: '100%',
    height: '100%'
}));

function News() {
    useEffect(() => {
        // scroll to the very top when page is triggered.
        window.scrollTo(0, 0);
    }, []);

    const secondStyle = {
        position: 'absolute',
        bottom: '0',
        left: '0'
    };

    return (
        <>
            <HeaderImg src={NewsHeader} alt="News" />
            <Container
                direction="row"
                gap={theme.spacing.m}
                padding={theme.spacing.l}
            >
                { STRINGS.newsPage.map((news, index) => (
                    <NewsItem key={index} href={news.link} target="_blank">
                        <Image src={news.image} />
                        <ContentContainer>
                            <Container
                                direction="column"
                                style={secondStyle}
                                padding={theme.spacing.xs}
                                alignItems="flex-start"
                            >
                                <NewsSource>{news.source}</NewsSource>
                                <NewsTitle>{news.title}</NewsTitle>
                            </Container>
                        </ContentContainer>
                    </NewsItem>
                ))}
            </Container>
        </>
    );
}

export default News;
