import { COLLECTION_NAME } from "@utils/globals";
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const RES = {
    images: {
        logo: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/Icon.png',
        horizontalLogo: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/horizontal-logo.svg',
        icon: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/Icon.png',

        backgroundImage: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/background-desktop.png',
        backgroundImageMobile: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/background-mobile.png',

        pack: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/Tommy%20Hearns%20Image%204.png',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        hearnsCutout: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/tommy-hearns-cutout.png',
        spotlight: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/hearns-spotlight-2.jpg',
        hero: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/tommy-hearns-hero.png',
        portrait: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/tommy-hearns-portrait.png'

    },

    openingAnimations: [
        {
            template_id: IS_PRODUCTION ? '298325' :'218325',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/TommyHearns_Pack_v1.mp4'
        }
    ],

    samples: [
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/base_impact.jpeg',
            caption: 'Base',
            chance: '33% probability'
        },
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/black_and_white_impact.jpeg',
            caption: 'Base Black & White',
            chance: '33% probability'
        },
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/base_impact_blk.jpeg',
            caption: 'Borders',
            chance: '20% probability'
        },
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/gold_impact_blk.jpeg',
            caption: 'Gold Foil',
            chance: '7.5% probability'
        },
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/camera_impact.mp4',
            caption: 'Camera Flash',
            chance: '5% probability',
            type: 'video'
        },
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/TommyHearnsBox_1%20Celebration.mp4',
            caption: 'Ringside',
            chance: '1% probability',
            type: 'video'
        },
        {
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/fanfavz/Hearns_FanFavz_1-minimized.png',
            caption: 'Legendary Moments',
            chance: '0.5% probability'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/base_blackjacket.jpg',
            caption: 'Mythical (Redeemable)',
            chance: '0.3% probability'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/insertcard002.jpg',
            caption: 'Gratitude',
            chance: '100% guaranteed insert'
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: `https://wax.atomichub.io/market?collection_name=${COLLECTION_NAME}`
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: `https://wax.atomichub.io/profile/{0}?collection_name=${COLLECTION_NAME}`
        },
        atomicHubEntireCollection: {
            label: "AtomicHub Tommy Hearns entire Collection",
            link: `https://wax.atomichub.io/tools/book/${COLLECTION_NAME}/{0}`
        },
        waxStash: {
            label: "WAXStash",
            link: `https://www.waxstash.com/markets?collection_name=${COLLECTION_NAME}`
        },
        nftHive: {
            label: "NFTHive",
            link: `https://nfthive.io/collection/fanfavz`
        }
    },

    externalLinks: {
        wcw: {
            label: "Wax Cloud Wallet",
            link: "https://all-access.wax.io"
        },
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/mW2654Nw3Z"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        fanfavzInstagram: {
            label: "FanFavz Instagram Account",
            link: "https://instagram.com/fanfavz?utm_medium=copy_link"
        },
        fanfavzTwitter: {
            label: "FanFavz Twitter Account",
            link: "https://twitter.com/FanFavz?utm_medium=copy_link"
        },
        fanfavzFacebook: {
            label: "FanFavz Facebook Account",
            link: "https://facebook.com/FanFavz?utm_medium=copy_link"
        }
    }
};

export default RES;
