import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import FAQItem from '@components/FAQItem';
import FAQHeader from '@images/tommy-hearns-faqs.png';

const Container = styled.div(({ theme }) => ({
    color: theme.colors.secondaryDark,
    maxWidth: '80ch',
    margin: 'auto',
    padding: `${theme.spacing.l} 0`
}));

const HeaderImg = styled.img(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    zIndex: 1,
    [ theme.mediaQuery.largeMobileOnly ]: {
        width: '100%',
        height: 'auto',
        maxHeight: '500px'
    }
}));

function FAQ() {
    const faqItems = STRINGS.faqItems.map((item, index) => <FAQItem key={index} question={item.question} answer={item.answer} />);

    useEffect(() => {
        // scroll to the very top when page is triggered.
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderImg src={FAQHeader} alt="FAQ" />
            <Container>
                {faqItems}
            </Container>
        </>
    );
}

export default FAQ;
