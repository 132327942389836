const breakpoints = [ 576, 768, 992, 1366 ];

const theme = {
    colors: {
        common: {
            text: '#FFFFFF',
            background: '#111111',
            lightBackground: '#363636'
        },
        inverted: {
            background: '#FFF',
            text: '#111'
        },
        primary: {
            main: '#bd1010',
            dark: '#B01717',
            contrastColor: '#FFF'
        },
        grey: {
            main: '#E8E8E8'
        },
        error: {
            dark: '#A91414',
            main: '#CA1515',
            light: '#E54C4C',
            lighter: '#FFDDDD',
            contrastText: '#FFFFFF'
        },
        warning: {
            dark: '#A99014',
            main: '#CAAC15',
            light: '#FFD569',
            contrastText: '#141414'
        },
        success: {
            dark: '#05532B',
            main: '#1E794B',
            light: '#64BC90',
            lighter: '#DDFFDD',
            contrastText: '#FFFFFF'
        },
        info: {
            main: '#007bff'
        },
        primaryDark: '#040404',
        primaryLight: '#FFFFFF',
        activeLight: '#EEEEEE',
        secondaryLight: '#F8F8F8',
        secondary: '#363636',
        secondaryDark: '#E6E6E6',
        tertiary: '#363636',
        tertiaryLight: '#363636',
        backgroundColor: '#E8E8E8',
        errorColorDark: '#A91414',
        errorColor: '#CA1515',
        errorColorLight: '#E54C4C',
        warningColorDark: '#A99014',
        warningColor: '#CAAC15',
        warningColorLight: '#FFD569',
        successColorDark: '#05532B',
        successColor: '#1E794B',
        successColorLight: '#64BC90'
    },
    spacing: {
        xxxs: '4px',
        xxs: '8px',
        xs: '16px',
        s: '24px',
        m: '32px',
        l: '40px',
        xl: '62px',
        xxl: '80px',
        xxxl: '106px'
    },
    borderRadius: {
        s: '4px',
        m: '8px',
        l: '16px'
    },
    mediaQuery: {
        mobileOnly: `@media (max-width: ${breakpoints[0] - 1}px)`,
        largeMobileOnly: `@media (max-width: ${breakpoints[1] - 1}px)`,
        largeMobileUp: `@media (min-width: ${breakpoints[0]}px)`,
        tabletUp: `@media (min-width: ${breakpoints[1]}px)`,
        tabletLandscapeUp: `@media (min-width: ${breakpoints[2]}px)`,
        desktopUp: `@media (min-width: ${breakpoints[3]}px)`
    },
    typography: {
        fontFamily: "'Lexend', sans-serif",
        h1: {
            fontSize: '48px',
            lineHeight: '130%',
            fontWeight: 300
        },
        h2: {
            fontSize: '32px',
            lineHeight: '130%',
            fontWeight: 700
        },
        h3: {
            fontSize: '28px',
            lineHeight: '130%',
            fontWeight: 700
        },
        h4: {
            fontSize: '24px',
            lineHeight: '130%',
            fontWeight: 500
        },
        h5: {
            fontSize: '20px',
            lineHeight: '130%',
            fontWeight: 500
        },
        h6: {
            fontSize: '18px',
            lineHeight: '150%',
            fontWeight: 500
        },
        p: {
            fontSize: '18px',
            lineHeight: '180%',
            fontWeight: 300
        },
        pBold: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 600,
            opacity: 0.9
        },
        pTiny: {
            fontSize: '14px',
            lineHeight: '150%',
            fontWeight: 400,
            opacity: 0.9
        }
    },
    size: {
        headerHeight: '60px',
        footerHeight: '207.16px',
        mintWidth: '60px',
        mintHeight: '25px',
        assetWidth: '270px',
        secondaryMarketLogosWidth: '150px',
        assetWidthTiny: '240px',
        loadingHeight: '100px',
        loadingWidth: '100px',
        loadingBorder: '8px',
        gridItemButtonsWidth: 200,
        dropdownWidth: 180,
        templateWidth: 100,
        templateHeight: 100
    },
    shadow: {
        dropShadow: '5px 5px 30px 2px rgba(0,0,0,0.2)',
        dropShadowGridButtons: '4px 4px 10px 4px #000000',
        dropShadowCard: '4px 4px 8px 2px #00000077'
    },
    transition: {
        default: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    },
    select: {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected || state.isFocused ? theme.colors.primary.contrastColor : theme.colors.primary.contrastColor,
            backgroundColor: state.isSelected || state.isFocused ? theme.colors.primary.main : theme.colors.common.lightBackground
        }),
        singleValue: (base) => ({
            ...base,
            color: theme.colors.primary.contrastColor
        }),
        control: (base) => ({
            ...base,
            border: `1px solid ${theme.colors.common.lightBackground}`,
            backgroundColor: theme.colors.common.lightBackground,
            '&:hover': { borderColor: theme.colors.primary.dark },
            boxShadow: 'none'
        }),
        placeholder: (base) => ({
            ...base,
            color: theme.colors.primary.contrastColor
        }),
        input: (base) => ({
            ...base,
            color: theme.colors.primary.contrastColor
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: theme.colors.primary.contrastColor,
            '&:hover': { color: theme.colors.primary.dark }
        }),
        clearIndicator: (base) => ({
            ...base,
            color: theme.colors.primary.contrastColor,
            '&:hover': { color: theme.colors.primary.dark }
        }),
        indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: theme.colors.primary.contrastColor
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: theme.colors.common.lightBackground
        })
    }
};

export default theme;
