import RES from '@utils/resources';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

let templateInfo = new Map();

export const getInfo = (templateId) => {
    return templateInfo.get(templateId);
};

export const setInfo = (templateId, info) => {
    return templateInfo.set(templateId, info);
};

if (IS_PRODUCTION) {
    setInfo(298325, {
        imgHash: 'QmTSsv2rM4MfXRFUvzpE3GJxTomgUCmpfBBd8WbER5zZnU',
        name: '12 Round Packs',
        isImg: false,
        variant: ''
    });
    // setInfo(208771, {
    //     imgHash: 'QmTFkaPMmrz4i7t2XDtBDt1QWfPQN6dfWMrPSz79xNtGgg',
    //     name: 'VIP Pass Pack',
    //     isImg: true,
    //     variant: ''
    // });
} else {
    setInfo(218325, {
        imgHash: 'QmTSsv2rM4MfXRFUvzpE3GJxTomgUCmpfBBd8WbER5zZnU',
        name: '12 Round Pack',
        isImg: false,
        variant: ''
    });
    // setInfo(134595, {
    //     imgHash: 'QmTFkaPMmrz4i7t2XDtBDt1QWfPQN6dfWMrPSz79xNtGgg',
    //     name: 'VIP Pass Pack',
    //     isImg: true,
    //     variant: ''
    // });
}
