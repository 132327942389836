import React from 'react';
import { useHistory } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';
import STRINGS from '@utils/strings';
import styled from '@emotion/styled';
import StyledButton from '@components/Button';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';

const Container = styled.div(() => ({
    textAlign: 'left'
}));

const StepNumber = styled.div(({ theme }) => ({
    display: 'flex',
    flex: 'none',
    borderRadius: '50%',
    background: '#000000',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '1.7rem',
    textAlign: 'center',
    width: '100px',
    height: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    [ theme.mediaQuery.mobileOnly ]: {
        alignSelf: 'center'
    }
}));

const StepContainer = styled.div(() => ({
    display: 'block'
}));

const Title = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    fontWeight: 400,
    color: theme.colors.inverted.text,
    marginBottom: theme.spacing.s
}));

const Description = styled.p(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: '180%',
    marginBottom: '1rem',
    color: theme.colors.inverted.text
}));

const ButtonsContainer = styled.div(({ theme }) => ({
    gap: theme.spacing.xs
}));

const ContentContainer = styled.div(({ theme, mainImage, portrait }) => ({
    display: 'flex',
    maxWidth: '120ch',
    margin: '0 auto',
    padding: theme.spacing.xs,
    gap: theme.spacing.l,
    [ theme.mediaQuery.mobileOnly ]: {
        flexDirection: 'column'
    },
    [ theme.mediaQuery.largeMobileOnly ]: {
        textAlign: 'center'
    },
    [ theme.mediaQuery.tabletLandscapeUp ]: {
    }
}));

function HowToBuy({ ual }) {

    const history = useHistory();
    const toShop = () => history.push(`${ROUTES.buypacks}`);
    const toInventory = () => history.push(`${ROUTES.inventory}`);
    const toAllAccess = () => window.open("https://all-access.wax.io");

    return (
        <Container>
            <ContentContainer>
                <StepNumber>1</StepNumber>
                <StepContainer>
                    <Title>Create a WAX Cloud Wallet (WCW)</Title>
                    <Description>The WAX Cloud Wallet is a lightweight and easy-to-use wallet account that you can use directly in your browser to hold and view your WAX NFTs. Instead of having to manage private keys, you can create a wallet with as little information as an email and password, or use any number of social accounts like Facebook and Twitter!</Description>
                    <ButtonsContainer>
                        <StyledButton onClick={toAllAccess}>Create WAX Cloud Wallet</StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
            <ContentContainer>
                <StepNumber>2</StepNumber>
                <StepContainer>
                    <Title>Login Using Your New WCW</Title>
                    <Description>Use the &quot;Log In&quot; button in the top right or the button below to initialize your WAX Cloud Wallet on the Tommy Hearns NFT website. A popup will appear and asking you to input your WCW information and authorize the login.</Description>
                    <ButtonsContainer>
                        <StyledButton disabled={ual.activeUser ? true : false} onClick={ual.showModal}>{ual.activeUser ? "You're already logged in" : STRINGS.logIn}</StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
            <ContentContainer>
                <StepNumber>3</StepNumber>
                <StepContainer>
                    <Title>Purchase Your Packs</Title>
                    <Description>Go to the &quot;Shop&quot; page, select the number of packs you would like to purchase, then click &quot;Buy Packs.&quot; Follow the onscreen prompts to fill out your credit or debt card information. Once you complete your purchase, it may take a few minutes for your packs to appear in your WCW.</Description>
                    <ButtonsContainer>
                        <StyledButton onClick={toShop}>Buy Packs</StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
            <ContentContainer>
                <StepNumber>4</StepNumber>
                <StepContainer>
                    <Title>Open Packs and View NFTs</Title>
                    <Description>Use the navbar to open packs and view your NFTs! You can also trade your NFTs on secondary markets like AtomicHub!</Description>
                    <ButtonsContainer>
                        <StyledButton disabled={ual.activeUser ? false : true} onClick={toInventory} >View Inventory</StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
        </Container>
    );
}

export default withUAL(HowToBuy);
