/* eslint import/extensions: "off" */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import StyledButton from '@components/Button';
import Countdown from '@components/Countdown';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import STRINGS from '@utils/strings';
import { isReleased, SHOW_HEADER } from '@utils/globals';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import Container from '@components/Container';
import HowToBuy from '@components/HowToBuy';
import Hero from '@components/Hero';

//test deploy

const InfoContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    // backgroundImage: `url(${RES.images.backgroundImageMobile})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto'
    // [ theme.mediaQuery.desktopUp ]: {
    //     backgroundImage: `url(${RES.images.backgroundImage})`,
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'bottom'
    // }
}));

const Headline = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '80ch',
    margin: `0 ${theme.spacing.xs} ${theme.spacing.xs}`,
    [ theme.mediaQuery.desktopUp ]: {
        margin: `0 0 ${theme.spacing.xs}`
    },
    'p': {
        marginBottom: theme.spacing.xs
    }
}));

const LauchAnnouncement = styled.p(({ theme }) => ({
    ...theme.typography.pBold,
    maxWidth: '65ch',
    textAlign: 'center',
    marginBottom: theme.spacing.xxs
}));

const ReleaseDate = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    maxWidth: '65ch',
    textAlign: 'center',
    marginTop: theme.spacing.l,
    marginBottom: 0
}));

const ReleaseTime = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65ch',
    textAlign: 'center',
    marginBottom: theme.spacing.m
}));

const UpcomingHeadline = styled.h2(({ theme }) => ({
    textTransform: 'uppercase',
    color: theme.colors.primaryLight,
    lineHeight: '130%',
    textAlign: 'center',
    margin: '16px 8px',
    letterSpacing: 2.8
}));

const UpcomingSubtext = styled.p(({ theme }) => ({
    color: theme.colors.primaryLight,
    lineHeight: '180%',
    textAlign: 'center',
    maxWidth: '60ch',
    fontSize: '1.125rem',
    fontWeight: 300
}));

function Home() {
    const theme = useTheme();
    const history = useHistory();

    const [ showAthleteFormModal, setShowAthleteFormModal ] = useState(false);
    const [ showFanFormModal, setShowFanFormModal ] = useState(false);

    useEffect(() => {
        // scroll to the very top when page is triggered.
        window.scrollTo(0, 0);
    }, []);

    const CustomGrid = {
        marginBottom: theme.spacing.m,
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            'img': {
                width: 270
            }
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            'img': {
                width: 270
            }
        }
    };

    return (
        <>
            <InfoContainer>
                <Hero
                    mainImage={RES.images.hero}
                    secondaryImage={false}
                    title={STRINGS.homePage.headline}
                    subtitle={STRINGS.homePage.subtext}
                    portrait={RES.images.portrait}
                    overlayButton
                    button
                    buttonCallback={isReleased() ? () => history.push(ROUTES.buypacks) : () => setShowFanFormModal(true)}
                />
                { isReleased() ?
                    <Container
                        direction="column"
                        style={{ backgroundColor: theme.colors.inverted.background }}
                        padding={theme.spacing.l}
                    >
                        <LauchAnnouncement>
                            {STRINGS.homePage.launched}
                        </LauchAnnouncement>
                        <StyledButton onClick={() => { history.push(ROUTES.buypacks);}}>
                            {STRINGS.buyPacks}
                        </StyledButton>
                    </Container>
                    :
                    <Container
                        direction="column"
                        style={{ backgroundColor: theme.colors.inverted.background,
                            color: theme.colors.inverted.text
                        }}
                        padding={theme.spacing.l}
                    >
                        <LauchAnnouncement>
                            {STRINGS.homePage.launchAnnoucement}
                        </LauchAnnouncement>
                        <Countdown />
                        <ReleaseDate>
                            {STRINGS.releaseDate}
                        </ReleaseDate>
                        <ReleaseTime>
                            {STRINGS.releaseTime}
                        </ReleaseTime>
                        <StyledButton
                            onClick={() => setShowFanFormModal(true)}
                            secondary={isReleased() ? true : false}
                        >
                            { isReleased() ? STRINGS.formButtonPostRelease : STRINGS.formButtonPreRelease }
                        </StyledButton>
                    </Container>
                }
                <Container
                    direction="column"
                    padding={theme.spacing.m}
                >
                    <Container
                        direction="column"
                        gap={theme.spacing.xs}
                        padding={theme.spacing.m}
                    >
                        <UpcomingHeadline>{STRINGS.upcomingTitle}</UpcomingHeadline>
                        <UpcomingSubtext>{STRINGS.upcomingSubtext}</UpcomingSubtext>
                    </Container>
                    <Grid items={RES.samples} style={CustomGrid} />
                </Container>
                <Container
                    direction="column"
                    padding={theme.spacing.l}
                    gap={theme.spacing.m}
                    style={{ backgroundColor: theme.colors.grey.main,
                        color: theme.colors.common.black
                    }}
                >
                    <Headline>{STRINGS.howToBuyHeadline}</Headline>
                    <Container>
                        <HowToBuy />
                    </Container>
                    <Container
                        direction="column"
                    >
                        <Headline>{STRINGS.charityHeadline}</Headline>
                        <Subtext>{STRINGS.charitySubtext}</Subtext>
                    </Container>
                </Container>
            </InfoContainer>
            <StyledFormModal
                show={showAthleteFormModal}
                onClose={() => setShowAthleteFormModal(false)}
                title={STRINGS.formTitleAthlete}
                portalId='19612576'
                formId='50e13798-446a-48cf-b0f9-4940f22dc569'
            />
            <StyledFormModal
                show={showFanFormModal}
                onClose={() => setShowFanFormModal(false)}
                title={STRINGS.formTitleFan}
                portalId='19612576'
                formId='f656c3e5-0c42-45e6-9968-264cc9f23df5'
            />
        </>
    );
}

export default Home;
