import React, { useState, useEffect } from 'react';
import STRINGS from '@utils/strings';
import { getCountriesList } from '@api/api';
import styled from '@emotion/styled';
import Button from '@components/Button';
import Container from '@components/Container';
import { useTheme } from '@emotion/react';
import { isEmail } from 'validator';

const Input = styled.input(({ theme }) => ({
    border: 'none',
    borderBottom: `1px solid ${theme.colors.primary.main}`,
    backgroundColor: theme.colors.common.background,
    color: theme.colors.primary.contrastColor,
    outline: 'none',
    width: '100%',
    fontSize: '18px',
    lineHeight: '160%',
    fontWeight: '600',
    '&::placeholder': {
        color: theme.colors.primary.contrastColor
    }
}));

const Select = styled.select(({ theme }) => ({
    border: 'none',
    borderBottom: `1px solid ${theme.colors.primary.main}`,
    backgroundColor: theme.colors.common.background,
    color: theme.colors.primary.contrastColor,
    outline: 'none',
    width: '100%',
    fontSize: '18px',
    lineHeight: '160%',
    fontWeight: '600',
    minHeight: '31px',
    '&::placeholder': {
        color: theme.colors.primary.main
    }
}));

function FormAddress({ formValues, onFullNameChange, onAddressChange, onFormValidated, onError, isPhysical, ...otherProps }) {
    const theme = useTheme();

    const [ countries, setCountries ] = useState([]);

    const onInputChange = (field, value) => {
        onAddressChange(field, value);
    };

    const createCountryOptions = (countriesResponse) => {
        let countryOptions = [];
        let selectedCountry = formValues.address[STRINGS.addressFields.country];

        for (let country of countriesResponse) {
            countryOptions.push(
                <option
                    value={country.name}
                    selected={selectedCountry ? country.name === selectedCountry : country.name === STRINGS.US}
                >
                    {country.name}
                </option>
            );
        }

        if (!formValues.address[STRINGS.addressFields.country]) {
            onInputChange(STRINGS.addressFields.country, STRINGS.US);
        }

        setCountries(countryOptions);
    };

    useEffect(() => {
        if (isPhysical) {
            getCountriesList(createCountryOptions, onError);
        }
    }, []);

    const verifySubmitEnabled = () => {
        return formValues.address[STRINGS.addressFields.fullName] && formValues.address[STRINGS.addressFields.addressLine1] &&
        formValues.address[STRINGS.addressFields.city] && formValues.address[STRINGS.addressFields.state] &&
        formValues.address[STRINGS.addressFields.country] && formValues.address[STRINGS.addressFields.zipCode] &&
        formValues.customer[STRINGS.customerFields.email] && isEmail(formValues.customer[STRINGS.customerFields.email]);
    };

    const verifyInformation = () => {
        onFormValidated(formValues);
    };

    const formContainerStyle = {
        display: 'flex',
        width: '100%',
        maxWidth: '400px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing.s
    };

    return (
        <Container>
            <form autoComplete="on" style={formContainerStyle}>
                { isPhysical ? (
                    <>
                        <Input
                            autoComplete="name"
                            id="full-name"
                            name="full-name"
                            value={formValues[STRINGS.addressFields.fullName]}
                            onChange={e => onInputChange(STRINGS.addressFields.fullName, e.target.value)}
                            placeholder={STRINGS.fullName + " *"}
                            title={STRINGS.fullName + " " + STRINGS.required}
                            type="text"
                            required
                        />
                        <Input
                            autoComplete="email"
                            id="email"
                            name="email"
                            value={formValues[STRINGS.customerFields.email]}
                            onChange={e => onInputChange(STRINGS.customerFields.email, e.target.value)}
                            placeholder={STRINGS.email + " *"}
                            title={STRINGS.email + " " + STRINGS.required}
                            type="text"
                            required
                        />
                        <Input
                            autoComplete="address-line1"
                            id="mailing-address-1"
                            name="mailing-address-1"
                            value={formValues.address[STRINGS.addressFields.addressLine1]}
                            onChange={e => onInputChange(STRINGS.addressFields.addressLine1, e.target.value)}
                            placeholder={STRINGS.addressLine1 + " *"}
                            title={STRINGS.addressLine1 + " " + STRINGS.required}
                            type="text"
                            required
                        />
                        <Input
                            autoComplete="address-line2"
                            id="mailing-address-2"
                            name="mailing-address-2"
                            value={formValues.address[STRINGS.addressFields.addressLine2]}
                            onChange={e => onInputChange(STRINGS.addressFields.addressLine2, e.target.value)}
                            placeholder={STRINGS.addressLine2}
                            title={STRINGS.addressLine2}
                            type="text"
                        />
                        <Input
                            autoComplete="address-line3"
                            id="mailing-address-3"
                            name="mailing-address-3"
                            value={formValues.address[STRINGS.addressFields.addressLine3]}
                            onChange={e => onInputChange(STRINGS.addressFields.addressLine3, e.target.value)}
                            placeholder={STRINGS.addressLine3}
                            title={STRINGS.addressLine3}
                            type="text"
                        />
                        <Input
                            autoComplete="address-line4"
                            id="mailing-address-4"
                            name="mailing-address-4"
                            value={formValues.address[STRINGS.addressFields.addressLine4]}
                            onChange={e => onInputChange(STRINGS.addressFields.addressLine4, e.target.value)}
                            placeholder={STRINGS.addressLine4}
                            title={STRINGS.addressLine4}
                            type="text"
                        />
                        <Input
                            autoComplete="address-level2"
                            id="city"
                            name="city"
                            value={formValues.address[STRINGS.addressFields.city]}
                            onChange={e => onInputChange(STRINGS.addressFields.city, e.target.value)}
                            placeholder={STRINGS.city + " *"}
                            title={STRINGS.city + " " + STRINGS.required}
                            type="text"
                            required
                        />
                        <Input
                            autoComplete="address-level1"
                            id="state"
                            name="state"
                            value={formValues.address[STRINGS.addressFields.state]}
                            onChange={e => onInputChange(STRINGS.addressFields.state, e.target.value)}
                            placeholder={STRINGS.state + " *"}
                            title={STRINGS.state + " " + STRINGS.required}
                            type="text"
                            required
                        />
                        <Select
                            autoComplete="country-name"
                            id="country"
                            name="country"
                            value={formValues.address[STRINGS.addressFields.country]}
                            onChange={e => onInputChange(STRINGS.addressFields.country, e.target.value)}
                            title={STRINGS.country + " " + STRINGS.required}
                            required
                        >
                            {countries}
                        </Select>
                        <Input
                            autoComplete="postal-code"
                            id="zip"
                            name="zip"
                            value={formValues.address[STRINGS.addressFields.zipCode]}
                            onChange={e => onInputChange(STRINGS.addressFields.zipCode, e.target.value)}
                            placeholder={STRINGS.zipCode + " *"}
                            title={STRINGS.zipCode + " " + STRINGS.required}
                            type="text"
                            required
                        />
                    </>
                ) : (
                    <>
                        <Input
                            autoComplete="name"
                            id="account-name"
                            name="account-name"
                            value={formValues[STRINGS.addressFields.accountName]}
                            onChange={e => onInputChange(STRINGS.addressFields.accountName, e.target.value)}
                            placeholder={STRINGS.accountName + " *"}
                            title={STRINGS.accountName + " " + STRINGS.required}
                            type="text"
                            required
                        />
                        <Input
                            autoComplete="email"
                            id="email"
                            name="email"
                            value={formValues[STRINGS.customerFields.email]}
                            onChange={e => onInputChange(STRINGS.customerFields.email, e.target.value)}
                            placeholder={STRINGS.email + " *"}
                            title={STRINGS.email + " " + STRINGS.required}
                            type="text"
                            required
                        />
                    </>
                ) }
                <Button
                    type="button"
                    onClick={verifyInformation}
                    disabled={isPhysical ? !verifySubmitEnabled() : null}
                >
                    {STRINGS.redeemAsset}
                </Button>
            </form>
        </Container>
    );
}

export default FormAddress;
