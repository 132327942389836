import STRINGS from '@utils/strings';
import styled from '@emotion/styled';
import StyledButton from '@components/Button';
import { isReleased } from '@utils/globals';
import RES from '@utils/resources';

const HeroContainer = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [ theme.mediaQuery.largeMobileOnly ]: {
        flexDirection: 'column'
    }
}));

const MainImg = styled.img(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    position: 'relative',
    zIndex: 1,
    [ theme.mediaQuery.largeMobileOnly ]: {
        width: '100%',
        height: 'auto',
        maxHeight: '500px'
    }
}));

const Logo = styled.img(() => ({
    height: '100%',
    width: '45px',
    objectFit: 'contain'
}));

const Title = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    lineHeight: '160%',
    fontWeight: 400,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.s
}));

const Subtitle = styled.div(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text
}));

const Description = styled.div(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: '180%',
    marginBottom: '1rem',
    color: theme.colors.common.text
}));

const Portrait = styled.img(({ theme }) => ({
    width: '100%',
    maxWidth: '500px',
    objectFit: 'contain'
}));

const ButtonsContainer = styled.div(({ theme }) => ({
    display: 'none',
    gap: theme.spacing.xs,
    ['button']: {
        margin: '0 auto'
    },
    [ theme.mediaQuery.mobileOnly]: {
        display: 'flex',
        marginBottom: theme.spacing.m
    },
    [ theme.mediaQuery.largeMobileUp]: {
        display: 'flex',
        marginBottom: theme.spacing.l
    },
    [ theme.mediaQuery.tabletUp]: {
        display: 'none'
    },
    [ theme.mediaQuery.desktopUp]: {
        display: 'none'
    }
}));

const OverlayButtonContainer = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xs,
    position: 'absolute',
    zIndex: 100,
    left: 0,
    width: '100%',
    [ theme.mediaQuery.mobileOnly]: {
        display: 'none'
    },
    [ theme.mediaQuery.largeMobileUp]: {
        display: 'none'
    },
    [ theme.mediaQuery.tabletUp]: {
        bottom: '15px',
        display: 'flex'
    },
    [ theme.mediaQuery.desktopUp]: {
        bottom: '50px',
        display: 'flex'
    }
}));

const OverlayLeft = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xs,
    width: '42%'
}));

const OverlayMiddle = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xs,
    width: '46%',
    textAlign: 'center',
    'button': {
        margin: '0 auto'
    }
}));

const OverlayRight = styled.div(({ theme }) => ({
    display: 'flex',
    width: '12%'
}));

const Container = styled.div(() => ({
    position: 'relative'
}));

const SecondImg = styled.img(({ theme }) => ({
    height: '180px',
    width: '180px',
    position: 'absolute',
    right: theme.spacing.xs,
    transform: 'translateX(8%)',
    top: '100%',
    display: 'none',
    [ theme.mediaQuery.tabletLandscapeUp ]: {
        display: 'block'
    }
}));

const ContentContainer = styled.div(({ theme, mainImage, portrait }) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: mainImage ? 'left' : 'center',
    maxWidth: '120ch',
    margin: '0 auto',
    padding: `${theme.spacing.l} ${theme.spacing.xs}`,
    gap: theme.spacing.xs,
    alignItems: !mainImage || !portrait ? 'center' : '',
    [ theme.mediaQuery.largeMobileOnly ]: {
        alignItems: 'center',
        textAlign: 'center'
    },
    [ theme.mediaQuery.tabletLandscapeUp ]: {
        flexDirection: 'row',
        padding: theme.spacing.l
    }
}));

function Hero({ mainImage, logo, title, subtitle, description, button, secondaryButton, portrait, buttonCallback, secondaryButtonCallback, overlayButton }) {

    return (
        <Container>
            <HeroContainer>
                { mainImage ? <MainImg src={mainImage} alt="image" /> : null }
                <OverlayButtonContainer>
                    <OverlayLeft />
                    <OverlayMiddle>
                        { overlayButton ? <StyledButton onClick={buttonCallback}>{ isReleased() ? STRINGS.buyPacks : STRINGS.formButtonPreRelease }</StyledButton> : null }
                    </OverlayMiddle>
                    <OverlayRight />
                </OverlayButtonContainer>
            </HeroContainer>
            <Container>
                <ContentContainer mainImage={mainImage}>
                    <Container>
                        { button || secondaryButton ?
                            <ButtonsContainer>
                                { button ? <StyledButton onClick={buttonCallback}>{ isReleased() ? STRINGS.buyPacks : STRINGS.formButtonPreRelease }</StyledButton> : null }
                                { secondaryButton ? <StyledButton secondary onClick={secondaryButtonCallback}>{secondaryButton}</StyledButton> : null }
                            </ButtonsContainer> : null }
                        { logo ? <Logo src={logo} /> : null }
                        { title ? <Title>{title}</Title> : null }
                        { subtitle ? <Subtitle>{subtitle}</Subtitle> : null }
                        { description ? <Description>{description}</Description> : null }
                    </Container>
                    { portrait ? <Portrait src={portrait} alt="Tommy Hearns Portrait" /> : null }
                </ContentContainer>
            </Container>
        </Container>
    );
}

export default Hero;
